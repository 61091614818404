import { createStore } from 'vuex'
import axios from 'axios';
import router from '../router' 
var API_URL = {
  login:"/login",
  logout:"/logout",
  resetPassword:"/reset-password",
  generatePassword:'/generate-password',
  userAllPermissions:"/user/get-all-permissions", 
  navigationItem:"/navigation-item/list", 
  
  //content
  contentList: "/content/list", 
  contentUpdate: "/content/update", 
  contentCreate: "/content/create", 
  contentDelete: "/content/delete",
  //article
  articleList: "/article/list", 
  articleUpdate: "/article/update", 
  articleCreate: "/article/create", 
  articleDelete: "/article/delete",
 ///article-type/list
  articleTypeList: "/article-type/list", 
  //offering
  offeringList: "/offering/list", 
  offeringUpdate: "/offering/update", 
  offeringCreate: "/offering/create", 
  offeringDelete: "/offering/delete",
  //knowledge
  knowledgeList: "/knowledge/list", 
  knowledgeUpdate: "/knowledge/update", 
  knowledgeCreate: "/knowledge/create", 
  knowledgeDelete: "/knowledge/delete",
  //WebsiteSetting
  websiteSettingList: "/website-setting/list", 
  websiteSettingUpdate: "/website-setting/update", 
  //carouselList
  carouselList:"carousel/list",
  carouselUpdate:"carousel/update",
  carouselCreate:"carousel/create",
  carouselDelete:"carousel/delete",
  //consultationForm
  consultationFormList:"consultation-form/list",
  consultationFormUpdate:"consultation-form/update",
  consultationFormCreate:"consultation-form/create",
  consultationFormDelete:"consultation-form/delete",
  //lawyer/list
  lawyerList:"lawyer/list",
  lawyerUpdate:"lawyer/update",
  lawyerCreate:"lawyer/create",
  lawyerDelete:"lawyer/delete",
}
export default createStore({
  state: {
    DOMAIN_URL :process.env.VUE_APP_BACKEND_URL,  
    SITE_NAME :process.env.VUE_APP_SITE_NAME,
    userInfo:{
      token:"",
      email:"",
      password:"",
      name:"", 
    },
    generatePassword:{},
    loginInfo:{},
    token: '', 
    sideBar: [], 
    ChangePswInfo:{},
    allPermissions:{},
    loading:false,
    alert:{
      show:false,
      msg:'新增成功!!',
      mode:'success'
    },
    alertTimer:null,

    //--api--
    articleType:{
      dropDown:[],  
    },
    article:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    knowledge:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    offering:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    content:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    websiteSetting:{ 
      search:[],  
      update:[],     
    },
    carousel:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],    
    },
    consultationForm:{
      dropDown:[],  
      search:[],  
      update:[],  
      delete:[],  
      create:[],  
    },
    lawyer:{
      search:[],  
      update:[],  
      delete:[],  
      create:[],   
    }
  },
  mutations: {  
    ShowLoaderPanel(state, payload) {
      state.loading= payload; 
    }, 
    ShowAlertPanel(state, payload) {
      state.alertTimer = null;
      state.alert={
        show:false
      }
      if(payload.data.header.message == "Success"){
        state.alert={
          show:true,
          msg:'新增成功!!',
          mode:'success'
        }
      }else state.alert={
        show:true,
        msg:'新增失敗!!',
        mode:'alert'
      }
      state.alertTimer=window.setTimeout(( () =>  
      state.alert={
        show:false
      }), 4000);
    }, 
    /*--dropDown--*/
    // SetDropDownUserList(state, payload) {
    //   state.dropDown.userList = payload;
    // },
    // SetDropDownRoleList(state, payload) {
    //   state.dropDown.roleList = payload;
    // },
    // SetDropDownDivisionList(state, payload) {
    //   state.dropDown.divisionList = payload;
    // },
    // SetDropDownAreaList(state, payload) {
    //   state.dropDown.areaList = payload;
    // },
    // SetDropDownHcpList(state, payload) {
    //   state.dropDown.hcpList = payload;
    // },
    // SetDropDownHcoList(state, payload) {
    //   state.dropDown.hcoList = payload;
    // },
    // SetDropDownSpecialtyList(state, payload) {
    //   state.dropDown.specialtyList = payload;
    // }, 
    // SetDropDownHcpProfessionalSubTypeList(state, payload) {
    //   state.dropDown.hcpProfessionalSubType = payload; 
    // }, 
    // SetDropDownContentFileTypeList(state, payload) {
    //   state.dropDown.contentFileType = payload; 
    // }, 
    /*--search--*/
    // SetSearchUserList(state, payload) {
    //   state.search.userList = payload; 
    // },
    // SetSearchAuthorityList(state, payload) {
    //   state.search.authorityList = payload; 
    // }, 
    // SetUpdateAuthority(state, payload) {
    //   state.update.authority = payload; 
    // }, 
    // SetSearchOrgList(state, payload) {
    //   state.search.orgList = payload; 
    // }, 
    // SetSearchAreaList(state, payload) {
    //   state.search.areaList = payload; 
    // }, 
    // SetSearchHcpList(state, payload) { 
    //   if( 'list' in payload ){ 
    //     state.search.hcpList = payload; 
    //   }else 
    //   state.download.hcp = payload; 
    // }, 
    // SetSearchKpiJoinLineByMrList(state, payload) {
    //   if( 'list' in payload ){  
    //     state.search.kpiJoinLineByMrList = payload;  
    //   }else 
    //     state.download.kpiJoinLineByMrList = payload; 
    // }, 
    // SetSearchKpiJoinLineByHcpList(state, payload) { 
    //   if( 'list' in payload ){ 
    //     state.search.kpiJoinLineByHcpList = payload;  
    //   }else 
    //     state.download.kpiJoinLineByHcpList = payload; 
    // }, 
    // /*--user--*/ 
    // SetUpdateUser(state, payload) {
    //   state.update.user = payload; 
    // }, 
    // SetDeleteUser(state, payload) {
    //   state.delete.user = payload; 
    // }, 
    // SetCreateUser(state, payload) {
    //   state.create.user = payload; 
    // },  
    // SetImportHcp(state, payload) {
    //   state.import.hcp = payload;  
    // }, 
    SetLoginInfo(state,payload){
      state.loginInfo = payload;  
    },
    SetLoginToken(state,payload) {
      state.token = payload;  
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));  
      this.dispatch('GetSideBar');
    },
    DeleteLoginToken(state) {
      state.userInfo.token="";
      state.userInfo.password="";
      state.token = "";
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo)); 
      router.push({ name: 'login' })
    },
    SetSideBar(state, payload) {  
      state.sideBar = payload;
      this.dispatch('GetAllPermissions');
    },
    SetChangePsw(state, payload) { 
      state.ChangePswInfo = payload;//監聽並關閉視窗
    },
    SetGeneratePassword(state, payload) { 
      state.generatePassword = payload;
    },
    SetAllPermissions(state, payload) {  
      state.allPermissions = payload;
    }, 
    /*--content--*/ 
    SetSearchContent(state, payload) { 
      state.content.search = payload;  
    }, 
    SetDropDownContent(state, payload) {
      state.content.dropDown = payload; 
    }, 
    SetUpdateContent(state, payload) {
      state.content.update = payload; 
    }, 
    SetDeleteContent(state, payload) {
      state.content.delete = payload; 
    }, 
    SetCreateContent(state, payload) {
      state.content.create = payload; 
    },
    /*--Articl--*/ 
    SetSearchArticle(state, payload) { 
      state.article.search = payload;  
    }, 
    SetDropDownArticle(state, payload) {
      state.article.dropDown = payload; 
    }, 
    SetUpdateArticle(state, payload) {
      state.article.update = payload; 
    }, 
    SetDeleteArticle(state, payload) {
      state.article.delete = payload; 
    }, 
    SetCreateArticle(state, payload) {
      state.article.create = payload; 
    },  
    /*--Knowledge--*/ 
    SetSearchKnowledge(state, payload) { 
      state.knowledge.search = payload;  
    }, 
    SetDropDownKnowledge(state, payload) {
      state.knowledge.dropDown = payload; 
    }, 
    SetUpdateKnowledge(state, payload) {
      state.knowledge.update = payload; 
    }, 
    SetDeleteKnowledge(state, payload) {
      state.knowledge.delete = payload; 
    }, 
    SetCreateKnowledge(state, payload) {
      state.knowledge.create = payload; 
    },  
    /*--offering--*/ 
    SetSearchOffering(state, payload) { 
      state.offering.search = payload;  
    }, 
    SetDropDownOffering(state, payload) {
      state.offering.dropDown = payload; 
    }, 
    SetUpdateOffering(state, payload) {
      state.offering.update = payload; 
    }, 
    SetDeleteOffering(state, payload) {
      state.offering.delete = payload; 
    }, 
    SetCreateOffering(state, payload) {
      state.offering.create = payload; 
    },  
    /*--ArticlType--*/  
    SetDropDownArticleType(state, payload) {
      state.articleType.dropDown = payload; 
    },   
    /*--WebsiteSetting--*/ 
    SetSearchWebsiteSetting(state, payload) { 
      state.websiteSetting.search = payload;  
    },  
    SetUpdateWebsiteSetting(state, payload) {
      state.websiteSetting.update = payload; 
    }, 
    /*--Lawyer--*/ 
    SetSearchLawyer(state, payload) { 
      state.lawyer.search = payload;  
    },  
    SetUpdateLawyer(state, payload) {
      state.lawyer.update = payload; 
    }, 
    SetDeleteLawyer(state, payload) {
      state.lawyer.delete = payload; 
    }, 
    SetCreateLawyer(state, payload) {
      state.lawyer.create = payload; 
    },  
    /*--carousel--*/ 
    SetSearchCarousel(state, payload) { 
      state.carousel.search = payload;  
    }, 
    SetDropDownCarousel(state, payload) {
      state.carousel.dropDown = payload; 
    }, 
    SetUpdateCarousel(state, payload) {
      state.carousel.update = payload; 
    }, 
    SetDeleteCarousel(state, payload) {
      state.carousel.delete = payload; 
    }, 
    SetCreateCarousel(state, payload) {
      state.carousel.create = payload; 
    },  
    /*--consultationForm--*/ 
    SetSearchConsultationForm(state, payload) { 
      state.consultationForm.search = payload;  
    }, 
    SetDropDownConsultationForm(state, payload) {
      state.consultationForm.dropDown = payload; 
    }, 
    SetUpdateConsultationForm(state, payload) {
      state.consultationForm.update = payload; 
    }, 
    SetDeleteConsultationForm(state, payload) {
      state.consultationForm.delete = payload; 
    }, 
    SetCreateConsultationForm(state, payload) {
      state.consultationForm.create = payload; 
    },  
  },
  getters: {
    CheckPermissions: (state) => (key) => {  
      for(var i in state.allPermissions){
        if(state.allPermissions[i].name==key)return true;
      }
      return true;
      // return false; 
    }, 
  },
  actions: { 
    GetGeneratePassword({ commit }, payload) { 
      axios.post(API_URL.generatePassword,
        {
          "number": payload, 
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
          }
        }
      ).then(result => { 
        commit('SetGeneratePassword',result);   
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetLoginToken({ commit }, payload) { 
    console.log(process.env.VUE_APP_BACKEND_URL) 
      axios.post(API_URL.login,
        {
          "email": payload.email,
          "g-recaptcha-response": payload['g-recaptcha-response'],
          "password": payload.password
        }
      ).then(result => { 
        commit('SetLoginInfo',result.data); 
        if (result.data.header.message == "Success") {
          this.state.userInfo.password=payload.saveToken? payload.password:"";
          this.state.userInfo.token=result.data.data.access_tokens;
          this.state.userInfo.name=result.data.data.name;
          this.state.userInfo.email=payload.email;  

          commit('SetLoginToken',result.data.data.access_tokens); 
          router.push({ name: 'home' })
        }

      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetSideBar({ commit }) {
      axios.post(API_URL.navigationItem,
        {
          "page": 1,
          "count": 100,
          "sort_field": "id",
          "sort_order": "asc",
          "is_output": 0
        }, {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => { 
          if (result.data.header.message == "Success") {
            commit('SetSideBar', result.data.data.list);
          }
        }).catch(error => {
          this.dispatch('Logout');
          throw new Error(`API ${error}`);
        });
    },
    Logout({ commit }) { 
      axios.post(API_URL.logout,
        {}, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      })
        .then(result => {
          result;
        }).catch(error => {
          throw new Error(`API ${error}`);
        }); 
        commit('DeleteLoginToken');
    },
    ChangePsw({commit},payload){
      commit('SetChangePsw', '');
      axios.post(API_URL.resetPassword,
        payload, 
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => { 
        if (result.data.header.message == "Success") {   
          if(this.state.userInfo.password){
            this.state.userInfo.password = payload.password;
            localStorage.setItem("userInfo", JSON.stringify(this.state.userInfo)); 
          }  
        } 
        commit('SetChangePsw', result); 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    GetAllPermissions({commit}){
      axios.post(API_URL.userAllPermissions,{},
        {
        headers: {
          Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
        }
      }
      ).then(result => {   
        if (result.data.header.message == "Success") {    
          commit('SetAllPermissions', result.data.data.list);  
        } 
      }).catch(error => {
        throw new Error(`API ${error}`);
      });
    },
    /*--dropDown--*/
    // GetDropDownUserList({ commit },payload) {
    //   axios.post(API_URL.userList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownUserList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownRoleList({ commit },payload) {
    //   axios.post(API_URL.roleList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownRoleList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownDivisionList({ commit },payload) {
    //   axios.post(API_URL.divisionList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownDivisionList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownAreaList({ commit },payload) {
    //   axios.post(API_URL.areaList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownAreaList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownHcpList({ commit },payload) { 
    //   axios.post(API_URL.hcpList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}`  
    //     }
    //   })
    //     .then(result => {  
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownHcpList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownHcoList({ commit },payload) {
    //   axios.post(API_URL.hcoList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}`  
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownHcoList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownSpecialtyList({ commit },payload) {
    //   axios.post(API_URL.specialtyList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}`  
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownSpecialtyList',result.data.data.list);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDropDownHcpProfessionalSubTypeList({ commit },payload) {
    //   axios.post(API_URL.hcpProfessionalSubType, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetDropDownHcpProfessionalSubTypeList',result.data.data.list); 
    //       }
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },  
    // /*--search--*/
    // GetSearchUserList({ commit },payload) {
    //   axios.post(API_URL.userList,
    //     payload, 
    //     {
    //     headers: {
    //       Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //     }
    //   })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchUserList',result.data.data);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchAuthorityList({ commit }) {
    //   axios.post(API_URL.authorityList, {},
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //       }
    //     })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchAuthorityList',result.data.data.authority);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetUpdateAuthority({ commit },payload) {
    //   axios.post(API_URL.authorityUpdate,payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}` // Bearer 跟 token 中間有一個空格
    //       }
    //     })
    //     .then(result => { 
    //       if (result.data.header.message == "Success") {
    //         commit('SetUpdateAuthority',result);
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchOrgList({ commit },payload) {
    //   axios.post(API_URL.orgList, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchOrgList',result.data.data); 
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchAreaList({ commit },payload) {
    //   axios.post(API_URL.areaList, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchAreaList',result.data.data); 
    //       }
    //     }).catch(error => {
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchHcpList({ commit },payload) {
    //   axios.post(API_URL.hcpList, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchHcpList',result.data.data); 
    //       }
    //     }).catch(error => {
 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchKpiJoinLineByMrList({ commit },payload) {
    //   axios.post(API_URL.KpiReport, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchKpiJoinLineByMrList',result.data.data); 
    //       }
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetSearchKpiJoinLineByHcpList({ commit },payload) {
    //   axios.post(API_URL.KpiReport, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {   
    //       if (result.data.header.message == "Success") {
    //         commit('SetSearchKpiJoinLineByHcpList',result.data.data); 
    //       }
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // /*--user--*/
    // GetCreateUser({ commit },payload) {
    //   axios.post(API_URL.userCreate, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {    
    //         commit('SetCreateUser',result);  
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetDeleteUser({ commit },payload) {
    //   axios.post(API_URL.userDelete, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {    
    //         commit('SetDeleteUser',result);  
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    // GetUpdateUser({ commit },payload) {
    //   axios.post(API_URL.userUpdate, payload,
    //     {
    //       headers: 
    //       {
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {    
    //         commit('SetUpdateUser',result);  
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    /*--upload--*/
    // GetImportHcp({ commit },payload) {
    //   commit('ShowLoaderPanel',true); 
    //   axios.post(API_URL.hcpImport, payload,
    //     {
    //       timeout: 1000*60*60*24,
    //       headers: 
    //       {
    //         'Content-Type':'multipart/form-data',
    //         Authorization: `Bearer ${this.state.token}`  
    //       }
    //     })
    //     .then(result => {    
    //         commit('SetImportHcp',result);  
    //         commit('ShowLoaderPanel',false); 
    //     }).catch(error => { 
    //       throw new Error(`API ${error}`);
    //     });
    // },
    
    /*--Content--*/
    GetSearchContent({ commit },payload) {
      axios.post(API_URL.contentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchContent',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownContent({ commit },payload) {
      axios.post(API_URL.contentList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownContent',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateContent({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.contentCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateContent',result);  
            commit('ShowLoaderPanel',false);  
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteContent({ commit },payload) {
      axios.post(API_URL.contentDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteContent',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateContent({ commit },payload) {
      axios.post(API_URL.contentUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateContent',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Article--*/
    GetSearchArticle({ commit },payload) { 
      axios.post(API_URL.articleList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchArticle',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownArticle({ commit },payload) {
      axios.post(API_URL.articleList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownArticle',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateArticle({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.articleCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateArticle',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteArticle({ commit },payload) {
      axios.post(API_URL.articleDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteArticle',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateArticle({ commit },payload) {
      axios.post(API_URL.articleUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateArticle',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Knowledge--*/
    GetSearchKnowledge({ commit },payload) { 
      axios.post(API_URL.knowledgeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchKnowledge',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownKnowledge',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateKnowledge({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.knowledgeCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateKnowledge',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteKnowledge',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateKnowledge({ commit },payload) {
      axios.post(API_URL.knowledgeUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateKnowledge',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--offering--*/
    GetSearchOffering({ commit },payload) { 
      axios.post(API_URL.offeringList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchOffering',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownOffering({ commit },payload) {
      axios.post(API_URL.offeringList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownOffering',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateOffering({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.offeringCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateOffering',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteOffering({ commit },payload) {
      axios.post(API_URL.offeringDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteOffering',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateOffering({ commit },payload) {
      axios.post(API_URL.offeringUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateOffering',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--articleType--*/ 
    GetDropDownArticleType({ commit },payload) {
      axios.post(API_URL.articleTypeList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownArticleType',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--WebsiteSetting--*/
    GetSearchWebsiteSetting({ commit },payload) {
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.websiteSettingList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}` 
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchWebsiteSetting',result.data.data); 
            commit('ShowLoaderPanel',false); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },  
    GetUpdateWebsiteSetting({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.websiteSettingUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateWebsiteSetting',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--Lawyer--*/
    GetSearchLawyer({ commit },payload) {
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.lawyerList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}` 
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchLawyer',result.data.data); 
            commit('ShowLoaderPanel',false); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },  
    GetUpdateLawyer({ commit },payload) {
      axios.post(API_URL.lawyerUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateLawyer',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateLawyer({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.lawyerCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
          console.log('result',result)
            commit('SetCreateLawyer',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteLawyer({ commit },payload) {
      axios.post(API_URL.lawyerDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteLawyer',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    /*--carousel--*/
    GetSearchCarousel({ commit },payload) { 
      axios.post(API_URL.carouselList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchCarousel',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownCarousel({ commit },payload) {
      axios.post(API_URL.carouselList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownCarousel',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateCarousel({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post('/carousel/create', payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
          console.log('result',result)
            commit('SetCreateCarousel',result);  
            commit('ShowLoaderPanel',false);
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteCarousel({ commit },payload) {
      axios.post(API_URL.carouselDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteCarousel',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateCarousel({ commit },payload) {
      axios.post(API_URL.carouselUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateCarousel',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    /*--consultationForm--*/
    GetSearchConsultationForm({ commit },payload) { 
      axios.post(API_URL.consultationFormList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetSearchConsultationForm',result.data.data); 
          }
        }).catch(error => {
 
          throw new Error(`API ${error}`);
        });
    },
    GetDropDownConsultationForm({ commit },payload) {
      axios.post(API_URL.consultationFormList, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {   
          if (result.data.header.message == "Success") {
            commit('SetDropDownConsultationForm',result.data.data.list); 
          }
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
    GetCreateConsultationForm({ commit },payload) { 
      commit('ShowLoaderPanel',true);
      axios.post(API_URL.consultationFormCreate, payload,
        {
          headers: 
          {
            'Content-Type':'multipart/form-data',
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetCreateConsultationForm',result);  
            commit('ShowLoaderPanel',false); 
            commit('ShowAlertPanel',result);
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetDeleteConsultationForm({ commit },payload) {
      axios.post(API_URL.consultationFormDelete , payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetDeleteConsultationForm',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    },
    GetUpdateConsultationForm({ commit },payload) {
      axios.post(API_URL.consultationFormUpdate, payload,
        {
          headers: 
          {
            Authorization: `Bearer ${this.state.token}`  
          }
        })
        .then(result => {    
            commit('SetUpdateConsultationForm',result);  
        }).catch(error => { 
          throw new Error(`API ${error}`);
        });
    }, 
  },
  modules: {
  }
})
 
 