<template>
  <div>
  </div>
</template>
<script>

export default {
  name: 'HomeView',
  components: {
  },
  computed: {
  }
  ,
  data() {
    return {
      msg: ''
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
 