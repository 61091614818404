<!-- 
	experience  // 經歷
	skill  		// 專長
	credential  // 辦案經歷
	title 		// 職稱
	content		// 學歷
 -->
<template>
	<div class="container-fluid">
		<div class="row px-md-4 my-3">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">律師名稱</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<input v-model="searchInfo.name" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-12 col-lg-6 ">
				<button class="search-btn" @click="ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row px-md-4 my-3">
			<div class="col-12  mb-3" style="display: flex;justify-content: flex-end;">
				<button class="upload-btn" @click="popForm_show('create', {})">
					<font-awesome-icon :icon="['fas', 'plus']" class="mx-1" />
					新增律師
				</button>
			</div>
		</div>
		<div class="row px-md-4 my-3">
			<div class="col-12 table-container">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th style="width: 170px;">照片</th>
							<th style="width: 100px;">律師名稱</th>
							<th style="width: 100px;">職稱</th>
							<th>建立時間</th>
							<th>修改時間</th>
							<!-- <th style="width:65px;">順序</th> -->
							<!-- <th style="width:80px;">啟用</th> -->
							<th style="width:80px;"> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="!searchLawyerList || !searchLawyerList.list || searchLawyerList.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template v-if="searchLawyerList && searchLawyerList.list && searchLawyerList.list.length != 0">
							<tr v-for="(item, index) in  searchLawyerList.list " :key="index">
								<td>
									<div v-if="item.lawyer_photo && item.lawyer_photo[0]"
										style="background-color:  rgb(209 209 209);">
										<img v-bind:src="'data:image/jpeg;base64,' + item.lawyer_photo[0].image"
											style="width: 100%;">
									</div>
								</td>
								<td>
									<span v-if="item.title" v-bind:hovertext="item.name"></span>
									{{ item.name }}
								</td>
								<td>
									<span v-if="item.title" v-bind:hovertext="item.title"></span>
									{{ item.title }}
								</td>
								<td>
									<!-- <span v-if="item.created_at" v-bind:hovertext="item.created_at"></span> -->
									{{ $UtcToDateTime(item.created_at) }}
								</td>
								<td>
									<!-- <span v-if="item.updated_at" v-bind:hovertext="item.updated_at"></span> -->
									{{ $UtcToDateTime(item.updated_at) }}
								</td>
								<!-- <td>
									<span v-if="item.order" v-bind:hovertext="item.order"></span>
									{{ item.order }}
								</td> -->
								<!-- <td>
									<span v-if="item.is_show" style="color: #4CAF50;">
										啟用
									</span>
									<span v-if="!item.is_show" style="color: #ed626a;">
										未啟用
									</span>
								</td> -->
								<td class="">
									<img src="../../assets/img/edit.png" class="list-icon"
										@click="popForm_show('edit', item)">
									<img src="../../assets/img/delete.png" class="list-icon"
										@click="popForm_show('delete', item)">
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel " v-if="popForm.show">
		<div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
			<div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
			<span class="xmark" @click="popForm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
						確定要刪除文章嗎？
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
						<button class="btn btn-primary " @click="popForm_save">Yes</button>
					</div>
				</div>
				<div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">照片</label>
					</div>
					<div class="col-12 col-md-9 mb-3" v-if="popForm.mode == 'create' || popForm.mode == 'edit'">
						<button class="addFile-btn" onclick="document.getElementById('getFile').click()">
							<font-awesome-icon :icon="['fas', 'plus']" class="me-2" />
							選擇檔案
						</button>
						<div style="visibility: hidden;height: 0;">
							<input type="file" id="getFile" accept="image/*" @change="fileChange" :key="popForm.formData">
						</div>
						<div class=" mt-3 fileInfoGroup">
							<span v-if="popForm.formData_info" class="file-info">
								<img src="../../assets/img/file.png"> {{ popForm.formData_info.name }} (
								{{ Math.round(popForm.formData_info.size / 1000000 * 100) / 100 }}MB)
							</span>
							<img v-if="popForm.formData_info" :src="previewObjectURL()" class="filePreview" alt="">
							<div class="errMsg"
								v-if="popForm.formData_info && popForm.formData_info.size / 1000000 * 100 / 100 > file_max">
								檔案超過{{ file_max }}MB無法上傳
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require"> 律師名稱</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<input v-model="popForm.form.name" class="form-control" placeholder="Please enter" />
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require"> 職稱</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<!-- <input v-model="popForm.form.title" class="form-control" placeholder="Please enter" /> -->
						<Multiselect v-model="popForm.form.title" placeholder="Please select"
							:options="['主持律師', '律師', '專業顧問', '法務', '秘書']" :searchable="true" :allow-empty="true">
						</Multiselect>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">學歷</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<div v-for="(item, index) in popForm.form.content" v-bind:key="index" class="inputArrGroup mb-1">
							<input v-model="popForm.form.content[index].name" class="form-control "
								placeholder="Please enter" />
							<button v-if="popForm.form.content.length != 1" class="btn  btn-delete "
								@click="deleteInputArr('content', index)">×</button>
							<button v-if="index == popForm.form.content.length - 1" class="btn btn-add"
								@click="addInputArr('content', index)">﹢</button>
						</div>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">專長</label>
					</div>
					<div class="col-12 col-md-9 mb-3" v-if="popForm.form.skill">
						<div v-for="(item, index) in popForm.form.skill" v-bind:key="index" class="inputArrGroup mb-1">
							<input v-model="popForm.form.skill[index].name" class="form-control "
								placeholder="Please enter" />
							<button v-if="popForm.form.skill.length != 1" class="btn  btn-delete "
								@click="deleteInputArr('skill', index)">×</button>
							<button v-if="index == popForm.form.skill.length - 1" class="btn btn-add"
								@click="addInputArr('skill', index)">﹢</button>
						</div>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">經歷</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<div v-for="(item, index) in popForm.form.experience" v-bind:key="index" class="inputArrGroup mb-1">
							<input v-model="popForm.form.experience[index].name" class="form-control "
								placeholder="Please enter" />
							<button v-if="popForm.form.experience.length != 1" class="btn  btn-delete "
								@click="deleteInputArr('experience', index)">×</button>
							<button v-if="index == popForm.form.experience.length - 1" class="btn btn-add"
								@click="addInputArr('experience', index)">﹢</button>
						</div>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3" v-if="popForm.form.credential">
						<label class="form-lable require">辦案經歷</label>
					</div>
					<div class="col-12 col-md-9 mb-3" v-if="popForm.form.credential">
						<div v-for="(item, index) in popForm.form.credential" v-bind:key="index" class="inputArrGroup mb-1">
							<input v-model="popForm.form.credential[index].name" class="form-control "
								placeholder="Please enter" />
							<button v-if="popForm.form.credential.length != 1" class="btn  btn-delete "
								@click="deleteInputArr('credential', index)">×</button>
							<button v-if="index == popForm.form.credential.length - 1" class="btn btn-add"
								@click="addInputArr('credential', index)">﹢</button>
						</div>
					</div>
					<!-- <div class="col-12 col-md-3 mb-0 mb-md-3">
							<label class="form-lable require">順序</label>
						</div>
						<div class="col-12 col-md-9 mb-3">
							<input v-model="popForm.form.order" class="form-control" placeholder="Please enter"
								type="number" />
						</div> -->
					<!-- <div class="col-12 col-md-3 mb-0 mb-md-3">
							<label class="form-lable  ">啟用</label>
						</div>
						<div class="col-12 col-md-9 mb-3">
							<div class="input-group input-check-group">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" id="is_show" value="is_show"
										v-model="popForm.form.is_show">
									<label class="form-check-label" for="is_show"> 啟用</label>
								</div>
							</div>
						</div> -->
					<!-- <div class="col-12 col-md-3 mb-0 mb-md-3">
							<label class="form-lable require">文章內容</label>
						</div>
						<div class="col-12 col-md-9 mb-3">
							<TinyMCE v-bind:tinymce-doc="popForm.form.content" @update-tiny="updateTiny"></TinyMCE> 
						</div> -->

					<div class="errMsg">{{ popForm.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="popForm_save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => {
		resolve(reader.result.split(',')[1]);
	}
	reader.onerror = error => reject(error);
});
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	searchUserList: {},
	name: 'LawyerManagement',
	components: {
		PaginationVue,
	},
	computed: {
		...mapState(
			{
				searchLawyerList: state => state.lawyer.search,
				createLawyer: state => state.lawyer.create,
				deleteLawyer: state => state.lawyer.delete,
				updateLawyer: state => state.lawyer.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		return {
			file_max: 2,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				formData: new FormData(),
				formData_info: null,
				formData_base64: null,
				form: {
					title: null,
					id: null,
					name: null,
					photo: null,
					content: [{ name: '', order: '' }],
					skill: [{ name: '', order: '' }],
					experience: [{ name: '', order: '' }],
					credential: [{ name: '', order: '' }],
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1);
	},
	watch: {
		searchLawyerList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createLawyer(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchLawyer({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				});
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		updateLawyer(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchLawyer({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				});
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		deleteLawyer(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			'GetSearchLawyer',
			'GetCreateLawyer',
			'GetDeleteLawyer',
			'GetUpdateLawyer'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;

			if (this.popForm.mode == "edit") {
				this.popForm.formData = new FormData();
				var file = this.dataURLtoFile('data:image/jpeg;base64,' + _item.lawyer_photo[0].image, "已上傳檔案");
				this.popForm.formData.append("image", file);
				this.popForm.formData_info = file;
				this.popForm.formData_base64 = _item.lawyer_photo[0].image;
				this.popForm.form = {
					title: _item.title,
					id: _item.id,
					name: _item.name,
					content: [{ name: '', order: '' }],
					skill: [{ name: '', order: '' }],
					experience: [{ name: '', order: '' }],
					credential: [{ name: '', order: '' }],
				}
				// content資料格式
				if (_item.content) {
					var lawyer_experience = [];
					_item.content.split('<br/>').forEach(element => {
						if (element.length) lawyer_experience.push({ name: element, order: 0 });
					})

					this.popForm.form.content = lawyer_experience;
				}

				if (_item.lawyer_skill.length != -1) this.popForm.form.skill = [];
				_item.lawyer_skill.forEach(element => {
					this.popForm.form.skill.push({ name: element.name, order: element.order });
				});
				if (_item.lawyer_experience.length != -1) this.popForm.form.experience = [];
				_item.lawyer_experience.forEach(element => {
					this.popForm.form.experience.push({ name: element.name, order: element.order });
				});
				if (_item.lawyer_credential.length != -1) this.popForm.form.credential = [];
				_item.lawyer_credential.forEach(element => {
					this.popForm.form.credential.push({ name: element.name, order: element.order });
				});
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			var _pf = this.popForm.form;
			if (this.popForm.mode == "delete") {
				this.GetDeleteLawyer({
					"id": this.popForm.itemData.id
				});
				return;
			}
			if (!this.popForm.formData_info) {
				this.popForm.msg = "請上傳照片";
				return;
			}
			if (!_pf.name) {
				this.popForm.msg = "律師名稱 為必填欄位";
				return;
			}
			if (!_pf.title) {
				this.popForm.msg = "職稱 為必填欄位";
				return;
			}
			if (!_pf.content) {
				this.popForm.msg = "簡易敘述 為必填欄位";
				return;
			}
			// if (!_pf.order && _pf.order != 0) {
			// 	this.popForm.msg = "順序 為必填欄位";
			// 	return;
			// } 

			var needReturn = 0;

			/* credential 辦案經歷 */
			if (!_pf.credential) {
				this.popForm.msg = "辦案經歷 不可為空";
				return;
			}
			var _credential = [];
			_pf.credential.forEach((element, index) => {
				_credential.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "辦案經歷 不可為空";
					needReturn = 1;
					return;
				}
			});

			/* experience 經歷 */
			if (!_pf.experience) {
				this.popForm.msg = "經歷 不可為空";
				return;
			}
			var _experience = [];
			_pf.experience.forEach((element, index) => {
				_experience.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "經歷 不可為空";
					needReturn = 1;
					return;
				}
			});

			/* skill 專長 */
			if (!_pf.skill) {
				this.popForm.msg = "專長 不可為空";
				return;
			}
			var _skill = [];
			_pf.skill.forEach((element, index) => {
				_skill.push({
					"name": element.name,
					"order": index
				})
				if (element.name == '') {
					this.popForm.msg = "專長 不可為空";
					needReturn = 1;
					return;
				}
			});

			/* content 學歷 */
			if (!_pf.content) {
				this.popForm.msg = "學歷 不可為空";
				return;
			}
			var _content = '';
			_pf.content.forEach((element, index) => {
				_content += ((index == 0 ? '' : '<br/>') + element.name);
				if (element.name == '') {
					this.popForm.msg = "學歷 不可為空";
					needReturn = 1;
					return;
				}
			});
			if (needReturn) return;

			var payload = {
				"name": _pf.name,
				"title": _pf.title,
				"content": _content,
				"skill": _skill,
				"experience": _experience,
				"credential": _credential,
				"photo": [
					{
						"image": this.popForm.formData_base64, "order": 0
					}
				]
			};
			if (this.popForm.mode == "create") {
				// console.log(payload)
				this.GetCreateLawyer(payload);
				return;
			}
			if (this.popForm.mode == "edit") {
				payload.id = _pf.id;
				//塞資料  
				this.GetUpdateLawyer(payload);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				formData: new FormData(),
				formData_base64: null,
				form: {
					title: null,
					id: null,
					name: null,
					photo: null,
					content: [{ name: '', order: '' }],
					skill: [{ name: '', order: '' }],
					experience: [{ name: '', order: '' }],
					credential: [{ name: '', order: '' }],
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
					}
				}
				if (this.searchInfo.name) payload.search.name = [this.searchInfo.name];
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchLawyer(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		},
		updateTiny(val) {
			this.popForm.form.content = val;
		},
		dataURLtoFile(dataurl, filename) {
			var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		},
		fileChange(e) {
			this.popForm.formData = new FormData();
			// this.popForm.formData.append('photo', [e.target.files[0], e.target.files[0], e.target.files[0]]); //放進上傳的檔案
			this.popForm.formData.append('image', e.target.files[0]); //放進上傳的檔案
			this.popForm.formData_info = e.target.files[0];
			this.popForm.msg = null;

			if ((e.target.files[0].size / 1000000 * 100 / 100) >= this.file_max) {
				this.popForm.msg = '檔案超過' + this.file_max + 'MB無法上傳';
				return
			}
			toBase64(this.popForm.formData_info).then(res => {
				this.popForm.formData_base64 = res;
				// console.log(res)
			}).catch(err => {
				console.log(err);
			})
		},
		previewObjectURL() {
			var objectURL = window.URL.createObjectURL(this.popForm.formData_info);
			return objectURL;
		},
		addInputArr(key) {
			if (this.popForm.form[key].length >= 20) return;
			this.popForm.form[key].push({ name: '', order: this.popForm.form[key].length })
		},
		deleteInputArr(key, index) {
			if (this.popForm.form[key].length <= 1) return;
			this.popForm.form[key].splice(index, 1);
		}
	}
}
</script>
 