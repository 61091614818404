<template>
	<div class="container-fluid">
		<div class="row px-md-4 my-3">
			<div class="col-4 col-lg-2 mb-2">
				<label class="form-lable">填單人</label>
			</div>
			<div class="col-8 col-lg-4 mb-2">
				<input v-model="searchInfo.name" class="form-control" placeholder="Please enter" />
			</div>
			<div class="col-12  col-lg-6 ">
				<button class="search-btn" @click="ChangePage(1, 'search')">
					搜尋
				</button>
			</div>
		</div>
		<div class="row px-md-4 my-3">
			<div class="col-12 table-container">
				<table class="table table-bordered">
					<thead>
						<tr>
							<th style="width: 100px;">填單人</th>
							<th>稱謂</th>
							<th>填單時間</th>
							<th style="width: 120px;">電話</th>
							<th style="width: 90px;">已處理</th>
							<th style="width: 90px;"></th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-if="!searchConsultationFormList || !searchConsultationFormList.list || searchConsultationFormList.list.length == 0">
							<td colspan="9" class="td_noData">Nodata</td>
						</tr>
						<template
							v-if="searchConsultationFormList && searchConsultationFormList.list && searchConsultationFormList.list.length != 0">
							<tr v-for="(item, index) in searchConsultationFormList.list" :key="index">

								<td>
									<span v-if="item.name" v-bind:hovertext="item.name"></span>
									{{ item.name }}
								</td>
								<td>
									<span v-if="item.gender == 'Male'" style="color: #2196F3;">
										先生
									</span>
									<span v-if="item.gender == 'Female'" style="color: #ed6282;">
										小姐
									</span>
								</td>
								<td>
									<span v-if="item.created_at" v-bind:hovertext="item.created_at"></span>
									{{ item.created_at }}
								</td>
								<td>
									<span v-if="item.mobile" v-bind:hovertext="item.mobile"></span>
									{{ item.mobile }}
								</td>
								<td>
									<span v-if="item.is_processed" style="color: #4CAF50;">
										已處理
									</span>
									<span v-if="!item.is_processed" style="color: #ed626a;">
										未處理
									</span>

								</td>
								<td class="">
									<img src="../../assets/img/edit.png" class="list-icon"
										@click="popForm_show('edit', item)">
									<img src="../../assets/img/delete.png" class="list-icon"
										@click="popForm_show('delete', item)">
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<div class="col-12">
				<PaginationVue v-bind:page-info="cm_pageInfo" v-bind:change-event="ChangePage"></PaginationVue>
			</div>
		</div>
	</div>
	<div class="pop-panel " v-if="popForm.show">
		<div class="pop-panel-container " v-bind:class="{ 'xl-panel': !(popForm.mode == 'delete') }">
			<div class="pop-panel-title" v-if="popForm.mode == 'upload'">上傳</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'delete'">刪除</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'edit'">編輯</div>
			<div class="pop-panel-title" v-if="popForm.mode == 'create'">新增</div>
			<span class="xmark" @click="popForm_clean()">
				✕
			</span>
			<div class="pop-panel-inner">
				<div class="row" v-if="popForm.mode == 'delete' || popForm.mode == 'download'">
					<div class="col-12 pop-panel-text" v-if="popForm.mode == 'delete'">
						確定要刪除文章嗎？
					</div>
					<div class="pop-panel-btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">No</button>
						<button class="btn btn-primary " @click="popForm_save">Yes</button>
					</div>
				</div>
				<div class="row" v-if="popForm.mode != 'delete' && popForm.mode != 'download'">

					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">填單人</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<input v-model="popForm.form.name" class="form-control" placeholder="Please enter" />
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable  ">稱謂</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<div class="input-group input-check-group">
							<div class="form-check">
								<input class="form-check-input" type="radio" id="Male" value="Male" name="gender"
									v-model="popForm.form.gender">
								<label class="form-check-label" for="Male">先生</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="radio" id="Female" value="Female" name="gender"
									v-model="popForm.form.gender">
								<label class="form-check-label" for="Female">小姐</label>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">電話</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<input v-model="popForm.form.mobile" class="form-control" placeholder="Please enter" />
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable require">簡易敘述</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<textarea v-model="popForm.form.question" class="form-control" placeholder="Please enter"
							rows="6"></textarea>
					</div>
					<div class="col-12 col-md-3 mb-0 mb-md-3">
						<label class="form-lable  ">已處理</label>
					</div>
					<div class="col-12 col-md-9 mb-3">
						<div class="input-group input-check-group">
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="is_processed" value="is_processed"
									v-model="popForm.form.is_processed">
								<label class="form-check-label" for="is_processed"> 已處理</label>
							</div>
						</div>
					</div>
					<div class="errMsg">{{ popForm.msg }}</div>
					<div class="cpp_btn">
						<button class="btn btn-outline-primary" @click="popForm_clean()">Cancel</button>
						<button class="btn btn-primary " @click="popForm_save">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import 'datatables.net-select-bs5'
import PaginationVue from '../../components/layout/Pagination.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	searchUserList: {},
	name: 'ConsultationForm',
	components: {
		PaginationVue,
	},
	computed: {
		...mapState(
			{
				// dropDownConsultationFormTypeList: state => state.consultationFormType.dropDown,
				dropDownConsultationFormList: state => state.consultationForm.dropDown,
				searchConsultationFormList: state => state.consultationForm.search,
				createConsultationForm: state => state.consultationForm.create,
				deleteConsultationForm: state => state.consultationForm.delete,
				updateConsultationForm: state => state.consultationForm.update,
			}
		),
		...mapGetters(['CheckPermissions'])
	},
	data() {
		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${year}/${month}/${day}`;
		}
		return {
			file_max: 100,
			tableModule: {
				searchHistory: null,
				searchCache: null,
				sort_order: "desc",
				sort_field: "id"
			},
			searchInfo: {
				name: null,
				checkedType: [],
				dateStart: null,
				dateEnd: null,
			},
			format,
			areaId: "",
			cm_pageInfo: {
				totalPage: 0,
				nowPage: 0,
			},
			popForm: {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					id: null,
					name: null,
					gender: null,
					mobile: null,
					question: null,
					is_processed: 0,
				},
				msg: ""
			}
		};
	},
	mounted() {
		this.ChangePage(1)
		// this.GetDropDownConsultationForm({
		// 	"page": 1,
		// 	"count": 100,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
		// this.GetDropDownConsultationFormType({
		// 	"page": 1,
		// 	"count": 100,
		// 	"sort_field": "id",
		// 	"sort_order": "desc",
		// 	"is_output": 0
		// })
		// this.popForm_show('download')
	},
	watch: {
		searchConsultationFormList: function (val) {
			this.cm_pageInfo = {
				totalPage: val.total_page,
				nowPage: val.page,
			}
			this.tableModule.searchHistory = this.tableModule.searchCache;
		},
		createConsultationForm(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchConsultationForm({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownConsultationForm({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				console.log(val.data)
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		updateConsultationForm(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.GetSearchConsultationForm({
					"page": 1,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0
				})
				// this.GetDropDownConsultationForm({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		},
		deleteConsultationForm(val) {
			if (val.data.header.message == "Success") {
				this.popForm_clean();
				this.ChangePage(1);
				// this.GetDropDownConsultationForm({
				// 	"page": 1,
				// 	"count": 100,
				// 	"sort_field": "id",
				// 	"sort_order": "desc",
				// 	"is_output": 0
				// })
			} else {
				this.popForm.msg = val.data.header.message;
				for (var i in val.data.data) {
					this.popForm.msg = val.data.data[i][0];
				}
			}
		}
	},
	methods: {
		...mapActions([
			// 'GetDropDownConsultationFormType',
			// 'GetDropDownConsultationForm',
			'GetSearchConsultationForm',
			'GetCreateConsultationForm',
			'GetDeleteConsultationForm',
			'GetUpdateConsultationForm'
		]),
		popForm_show(_mode, _item) {
			this.popForm.show = true;
			this.popForm.mode = _mode;
			this.popForm.itemData = _item;
			if (this.popForm.mode == "edit") {
				this.popForm.form = {
					name: _item.name,
					gender: _item.gender,
					mobile: _item.mobile,
					question: _item.question,
					is_processed: _item.is_processed ? ['is_processed'] : [],
				}
			}
		},
		popForm_save() {
			this.popForm.msg = "";
			if (this.popForm.mode == "delete") {
				this.GetDeleteConsultationForm({
					"id": this.popForm.itemData.id
				});
				return;
			}

			if (!this.popForm.form.name) {
				this.popForm.msg = "填單人 為必填欄位";
				return;
			}
			if (!this.popForm.form.gender) {
				this.popForm.msg = "稱謂 為必填欄位";
				return;
			}
			if (!this.popForm.form.mobile) {
				this.popForm.msg = "電話 為必填欄位";
				return;
			}
			if (!this.popForm.form.question) {
				this.popForm.msg = "簡易敘述 為必填欄位";
				return;
			}

			var payload;
			payload = {
				"name": this.popForm.form.name,
				"gender": this.popForm.form.gender,
				"question": this.popForm.form.question,
				"mobile": this.popForm.form.mobile,
				"is_processed": this.popForm.form.is_processed.indexOf('is_processed') != -1 ? 1 : 0,
			};
			if (this.popForm.mode == "upload") {
				this.GetCreateConsultationForm(payload);
				return;
			}
			if (this.popForm.mode == "edit") {
				payload.id = this.popForm.itemData.id;
				//塞資料  
				this.GetUpdateConsultationForm(payload);
				return;
			}
		},
		popForm_clean() {
			this.popForm = {
				mode: "",
				show: false,
				isStart: false,
				isStarted: false,
				timer: null,
				itemData: {},
				form: {
					// consultationForm_type: null,
					title: null,
					desc: null,
					content: null,
					is_show: ['is_show'],
					order: null
				},
				msg: ""
			}
		},
		ChangePage(num, mode) {
			var payload = {};
			if (mode == 'search' || !this.tableModule.searchHistory) {
				payload = {
					"page": num,
					"count": 10,
					"sort_field": "id",
					"sort_order": "desc",
					"is_output": 0,
					"search": {
					}
				}
				if (this.searchInfo.name) payload.search.name = [this.searchInfo.name];
				console.log(payload)
				this.tableModule.searchCache = payload;
			} else {
				this.tableModule.searchCache = this.tableModule.searchHistory;
				this.tableModule.searchCache.page = num;
				this.tableModule.searchCache.sort_field = this.tableModule.sort_field
				this.tableModule.searchCache.sort_order = this.tableModule.sort_order
			}
			this.tableModule.searchCache.is_output = mode == 'download' ? 1 : 0;
			this.GetSearchConsultationForm(this.tableModule.searchCache);
		},
		copyEvent(id) {
			var str = document.getElementById(id + '_link');
			window.getSelection().selectAllChildren(str);
			document.execCommand("Copy")
		},
		updateTiny(val) {
			console.log('updateTiny')
			this.popForm.form.content = val;
		},
		// getConsultationFormTypeItem(id) {
		// 	for (var i in this.dropDownConsultationFormTypeList) {
		// 		if (this.dropDownConsultationFormTypeList[i].id == id) {
		// 			return this.dropDownConsultationFormTypeList[i]
		// 		}
		// 	} return null
		// },
	}
}
</script>
 